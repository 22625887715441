import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import MenuLink from '../../metis/MenuLink';
import { ProductIcon } from '../../dashboard';

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'package-list',
                              'package-create',
                              'package-edit',
                              'package-show',
                              'package-category-list',
                              'package-category-edit',
                              'package-category-create',
                              'package-category-delete',
                              'package-enquiry-list',
                              'package-enquiry-edit',
                              'package-enquiry-create',
                              'package-enquiry-delete',
                              'vehicle-list',
                              'vehicle-create',
                              'vehicle-edit',
                              'vehicle-show',
                              'hotel-category-list',
                              'hotel-category-edit',
                              'hotel-category-create',
                              'hotel-category-delete',
                              'destination-list',
                              'destination-create',
                              'destination-edit',
                              'destination-show',
                              'destination-category-list',
                              'destination-category-create',
                              'destination-category-edit',
                              'destination-category-show'
                        ]}
                  >
                        <MenuSeparator>Tour Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'package-list',
                                    'package-create',
                                    'package-edit',
                                    'package-show',
                                    'vehicle-list',
                                    'vehicle-create',
                                    'vehicle-edit',
                                    'vehicle-show',
                                    'package-category-list',
                                    'package-category-edit',
                                    'package-category-create',
                                    'package-category-delete',
                                    'package-enquiry-list',
                                    'package-enquiry-edit',
                                    'package-enquiry-create',
                                    'package-enquiry-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Package List"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/packages',
                                          '/dashboard/packages/create',
                                          '/dashboard/packages/:packageId/edit',
                                          '/dashboard/packages/:packageId/show',
                                          '/dashboard/package_categories',
                                          '/dashboard/package_categories/create',
                                          '/dashboard/package_categories/:categoryId/edit',
                                          '/dashboard/package_categories/:categoryId/show',
                                          '/dashboard/vehicles',
                                          '/dashboard/vehicles/create',
                                          '/dashboard/vehicles/:vehicleId/edit',
                                          '/dashboard/vehicles/:vehicleId/show',
                                          '/dashboard/package_enquiries',
                                          '/dashboard/package_enquiries/create',
                                          '/dashboard/package_enquiries/:enquiryId/edit',
                                          '/dashboard/package_enquiries/:enquiryId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'package-list',
                                                'package-create',
                                                'package-edit',
                                                'package-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Package List"
                                                link="/dashboard/packages"
                                                links={[
                                                      '/dashboard/packages',
                                                      '/dashboard/packages/create',
                                                      '/dashboard/packages/:packageId/edit',
                                                      '/dashboard/packages/:packageId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'package-category-list',
                                                'package-category-create',
                                                'package-category-edit',
                                                'package-category-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category List"
                                                link="/dashboard/package_categories"
                                                links={[
                                                      '/dashboard/package_categories',
                                                      '/dashboard/package_categories/create',
                                                      '/dashboard/package_categories/:categoryId/edit',
                                                      '/dashboard/package_categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'vehicle-list',
                                                'vehicle-create',
                                                'vehicle-edit',
                                                'vehicle-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Vehicle List"
                                                link="/dashboard/vehicles"
                                                links={[
                                                      '/dashboard/vehicles',
                                                      '/dashboard/vehicles/create',
                                                      '/dashboard/vehicles/:vehicleId/edit',
                                                      '/dashboard/vehicles/:vehicleId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'package-enquiry-list',
                                                'package-enquiry-create',
                                                'package-enquiry-edit',
                                                'package-enquiry-show'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Enquiry List"
                                                link="/dashboard/package_enquiries"
                                                links={[
                                                      '/dashboard/package_enquiries',
                                                      '/dashboard/package_enquiries/create',
                                                      '/dashboard/package_enquiries/:enquiryId/edit',
                                                      '/dashboard/package_enquiries/:enquiryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'destination-list',
                                    'destination-create',
                                    'destination-edit',
                                    'destination-delete',
                                    'destination-category-list',
                                    'destination-category-create',
                                    'destination-category-edit',
                                    'destination-category-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Destination Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/destinations',
                                          '/dashboard/destinations/create',
                                          '/dashboard/destinations/:destinationId/edit',
                                          '/dashboard/destinations/:destinationId/show',
                                          '/dashboard/destination_categories',
                                          '/dashboard/destination_categories/create',
                                          '/dashboard/destination_categories/:categoryId/edit',
                                          '/dashboard/destination_categories/:categoryId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'destination-category-list',
                                                'destination-category-create',
                                                'destination-category-edit',
                                                'destination-category-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category List"
                                                link="/dashboard/destination_categories"
                                                links={[
                                                      '/dashboard/destination_categories',
                                                      '/dashboard/destination_categories/create',
                                                      '/dashboard/destination_categories/:categoryId/edit',
                                                      '/dashboard/destination_categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'destination-list',
                                                'destination-create',
                                                'destination-edit',
                                                'destination-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Destination List"
                                                link="/dashboard/destinations"
                                                links={[
                                                      '/dashboard/destinations',
                                                      '/dashboard/destinations/create',
                                                      '/dashboard/destinations/:destinationId/edit',
                                                      '/dashboard/destinations/:destinationId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'hotel-category-list',
                                    'hotel-category-create',
                                    'hotel-category-edit',
                                    'hotel-category-delete'
                              ]}
                        >
                              <MenuItem
                                    title="Hotel Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          '/dashboard/hotel_categories',
                                          '/dashboard/hotel_categories/create',
                                          '/dashboard/hotel_categories/:categoryId/edit',
                                          '/dashboard/hotel_categories/:categoryId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'hotel-category-list',
                                                'hotel-category-create',
                                                'hotel-category-edit',
                                                'hotel-category-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category List"
                                                link="/dashboard/hotel_categories"
                                                links={[
                                                      '/dashboard/hotel_categories',
                                                      '/dashboard/hotel_categories/create',
                                                      '/dashboard/hotel_categories/:categoryId/edit',
                                                      '/dashboard/hotel_categories/:categoryId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
