import React from 'react';
import CheckMultiplePermission from '../../roles/CheckMultiplePermission';
import { MenuSeparator } from '../../navigation/styles';
import MenuItem from '../../metis/MenuItem';
import { NavLink } from 'react-router-dom';
import UserIcon from '../../dashboard/UserIcon';
import MenuLink from '../../metis/MenuLink';

const UserManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              'user-list',
                              'user-create',
                              'user-edit',
                              'user-show',
                              'role-list',
                              'role-create',
                              'role-edit',
                              'role-delete',
                              'agent-list',
                              'agent-delete',
                              'agent-request-list',
                              'agent-request-show'
                        ]}
                  >
                        <MenuSeparator>User Manages</MenuSeparator>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'user-list',
                                    'user-create',
                                    'user-edit',
                                    'user-show',
                                    'role-list',
                                    'role-create',
                                    'role-edit',
                                    'role-delete'
                              ]}
                        >
                              <MenuItem
                                    title="User Manage"
                                    icon={<UserIcon />}
                                    links={[
                                          '/dashboard/users',
                                          '/dashboard/users/create',
                                          '/dashboard/users/:userId/edit',
                                          '/dashboard/users/:userId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'user-list',
                                                'user-create',
                                                'user-edit',
                                                'user-delete'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/users">
                                                      All User
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'role-list',
                                                'role-create',
                                                'role-edit',
                                                'role-delete'
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/roles">
                                                      Role & Permission
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    'agent-list',
                                    'agent-delete',
                                    'agent-request-list',
                                    'agent-request-show'
                              ]}
                        >
                              <MenuItem
                                    title="Agent Manage"
                                    icon={<UserIcon />}
                                    links={[
                                          '/dashboard/agents',
                                          '/dashboard/agents/create',
                                          '/dashboard/agents/:agentId/edit',
                                          '/dashboard/agents/:agentId/show',
                                          '/dashboard/agent_requests',
                                          '/dashboard/agent_requests/create',
                                          '/dashboard/agent_requests/:agentId/edit',
                                          '/dashboard/agent_requests/:agentId/show'
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'agent-list',
                                                'agent-create',
                                                'agent-edit',
                                                'agent-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/agents"
                                                title="Agent List"
                                                links={[
                                                      '/dashboard/agents',
                                                      '/dashboard/agents/create',
                                                      '/dashboard/agents/:agentId/edit',
                                                      '/dashboard/agents/:agentId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                'agent-request-list',
                                                'agent-request-delete'
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/agent_requests"
                                                title="Agent Request List"
                                                links={[
                                                      '/dashboard/agent_requests',
                                                      '/dashboard/agent_requests/create',
                                                      '/dashboard/agent_requests/:agentId/edit',
                                                      '/dashboard/agent_requests/:agentId/show'
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default UserManage;
