import React from 'react';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
      return (
            <>
                  <Helmet>
                        <title>Dashboard</title>
                  </Helmet>
            </>
      );
};

export default Dashboard;
