import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import { Helmet } from 'react-helmet';

const PackageCategoryShow = () => {
      const { categoryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [category, setCategory] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/package_categories/${categoryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategory(response.data.packageCategory);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <title>Category Details</title>
                  </Helmet>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Category Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/package_categories"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={7}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              category.title
                                                                        }
                                                                  />

                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              category.date
                                                                        }
                                                                  />

                                                                  <ItemShow
                                                                        title="User Name"
                                                                        value={
                                                                              category.user_name
                                                                        }
                                                                  />

                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              category.status ? (
                                                                                    <>
                                                                                          <span className="text-success">
                                                                                                Active
                                                                                          </span>
                                                                                    </>
                                                                              ) : (
                                                                                    <>
                                                                                          <span className="text-warning">
                                                                                                Disable
                                                                                          </span>
                                                                                    </>
                                                                              )
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="text-center">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PackageCategoryShow;
