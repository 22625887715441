import React from 'react';
import { Spinner } from 'react-bootstrap';
import { SubmitButton } from './style';

const FormButton = ({ title, loading, type, handleButton, width }) => {
      return (
            <>
                  <SubmitButton
                        width={width}
                        type={type || 'submit'}
                        onClick={handleButton}
                  >
                        {loading ? (
                              <Spinner
                                    style={{
                                          height: '18px',
                                          width: '18px',
                                          marginLeft: '6px'
                                    }}
                                    animation="border"
                                    role="status"
                              />
                        ) : (
                              title
                        )}
                  </SubmitButton>
            </>
      );
};

export default FormButton;
