import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';

import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';

import CardHeader from '../../components/common/CardHeader';
import AgentListBox from '../../components/backend/AgentListBox';
import { Helmet } from 'react-helmet';
import LinkButton from '../../components/common/LinkButton';

const AirlineList = () => {
      const [loading, setLoading] = useState(false);
      const [airlines, setAirlines] = useState([]);
      const [totalAirlines, setTotalAirlines] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchAgents = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setAirlines(response.data.airlines);
                        setTotalAirlines(response.data.totalAirlines);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchAgents();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setAirlines(response.data.airlines);
                              setTotalAirlines(response.data.totalAirlines);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Helmet>
                        <title>Airline List</title>
                  </Helmet>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Airline List">
                                          <LinkButton
                                                link="/dashboard/airlines/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <AgentListBox
                                          loading={loading}
                                          agents={airlines}
                                          totalAgents={totalAirlines}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          fetchAgents={fetchAgents}
                                          setLoading={setLoading}
                                          setSearch={setSearch}
                                          setAgents={setAirlines}
                                    />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default AirlineList;
