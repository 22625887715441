import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { FormButton, FormTextarea } from '../../components/form';

const RejectModal = ({ slug, show, handleClose, agent, setAgent }) => {
      const [loading, setLoading] = useState(false);
      const [notes, setNotes] = useState('');

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            $('button[type=submit]').prop('disabled', true);

            const data = {
                  remarks: notes
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/agent_requests/${slug}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === 'success') {
                                    toast.success(response.data.message);
                                    setAgent({
                                          ...agent,
                                          ['status']: 'Rejected'
                                    });
                                    setAgent({ ...agent, ['note']: notes });
                                    handleClose();
                              }
                              if (response.data.result === 'error') {
                                    if (response.data.message.remarks) {
                                          toast.error(
                                                response.data.message.remarks
                                          );
                                    }
                              }
                              $('button[type=submit]').prop('disabled', false);
                              setLoading(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              toast.error(error.message);
                              $('button[type=submit]').prop('disabled', false);
                              setLoading(false);
                        }, 3000);
                  });
      };
      return (
            <>
                  <Modal
                        size="lg"
                        show={show}
                        backdrop="static"
                        onHide={handleClose}
                  >
                        <Modal.Header closeButton className="py-2">
                              <Modal.Title className="m-0">Reply</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <Form onSubmit={handleSubmit}>
                                    <FormTextarea
                                          title="Remarks"
                                          handleChange={setNotes}
                                    />
                                    <div className="mt-4">
                                          <FormButton
                                                title="Submit"
                                                loading={loading}
                                          />
                                    </div>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default RejectModal;
