import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Card, Col, ListGroupItem, Row, Spinner } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';

const AirlineShow = () => {
      const { airlineId } = useParams();
      const [airline, setAirline] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines/${airlineId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setAirline(response.data.airline);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Airline Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/airlines"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={5}>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              airline?.title
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Code"
                                                                        value={
                                                                              airline?.code
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              airline.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={`${airline.status ? 'Active' : 'Disable'}`}
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              airline.user_name
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                      <Col lg={5}>
                                                            <ListGroup>
                                                                  <ListGroupItem>
                                                                        <img
                                                                              src={
                                                                                    airline.image
                                                                              }
                                                                              alt={
                                                                                    airline.title
                                                                              }
                                                                              className="w-100"
                                                                        />
                                                                  </ListGroupItem>
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="text-center">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default AirlineShow;
