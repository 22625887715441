import { Accordion, Row } from 'react-bootstrap';
import FormInput from '../FormInput';
import FormTextarea from '../FormTextarea';

const FormSeo = ({
      setSeoTitle,
      title,
      setSeoDescription,
      description,
      setSeoKeyword,
      keyword
}) => {
      return (
            <>
                  <Accordion className="mt-4">
                        <Accordion.Item eventKey="2">
                              <Accordion.Header>Manage Seo</Accordion.Header>
                              <Accordion.Body>
                                    <Row className="g-3">
                                          <FormInput
                                                value={title}
                                                title="Seo Title"
                                                handleChange={setSeoTitle}
                                          />
                                          <FormInput
                                                value={keyword}
                                                title="Seo Keyword"
                                                handleChange={setSeoKeyword}
                                          />
                                          <FormTextarea
                                                value={description}
                                                title="Seo Description"
                                                handleChange={setSeoDescription}
                                          />
                                    </Row>
                              </Accordion.Body>
                        </Accordion.Item>
                  </Accordion>
            </>
      );
};

export default FormSeo;
