import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';
import { ItemShow } from '../../components/form';
import { Helmet } from 'react-helmet';

const PackageEnquiryShow = () => {
      const { enquiryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [item, setItem] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/package_enquiries/${enquiryId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setItem(response.data.enquiry);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <title>Enquiry Details</title>
                  </Helmet>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Package Enquiry Detail">
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/package_enquiries"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          <Row>
                                                <Col lg={7}>
                                                      <ListGroup>
                                                            <ItemShow
                                                                  title="Destination"
                                                                  value={
                                                                        item.destination
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Full Name"
                                                                  value={
                                                                        item.name
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Email"
                                                                  value={
                                                                        item.email
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Mobile No. (Whatsapp)"
                                                                  value={
                                                                        item.mobile
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="No. of Traveller"
                                                                  value={
                                                                        item.no_of_traveller
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Leaving From"
                                                                  value={
                                                                        item.leaving_from
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Leaving on"
                                                                  value={
                                                                        item.leaving_on
                                                                  }
                                                            />
                                                            <ItemShow
                                                                  title="Query"
                                                                  value={
                                                                        item.query
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="Date"
                                                                  value={
                                                                        item.date
                                                                  }
                                                            />

                                                            <ItemShow
                                                                  title="User Name"
                                                                  value={
                                                                        item.user_name
                                                                  }
                                                            />
                                                      </ListGroup>
                                                </Col>
                                          </Row>
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default PackageEnquiryShow;
