import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Accordion, Card, Col, Row, Spinner } from 'react-bootstrap';
import { ItemShow } from '../../components/form';
import toast from 'react-hot-toast';
import ImageSlider from '../../components/sliders';
import { Helmet } from 'react-helmet';

const ProductShow = () => {
      const { packageId } = useParams();

      const [loading, setLoading] = useState(false);
      const [product, setProduct] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/packages/${packageId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProduct(response.data.package);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      console.log(product);

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <title>Package Detail</title>
                  </Helmet>
                  <Row>
                        <Col xs={12}>
                              <Card>
                                    <Card.Header>
                                          <Card.Title>
                                                Package Detail
                                          </Card.Title>
                                          <Link
                                                to="/dashboard/packages"
                                                className="btn btn-link"
                                          >
                                                Back
                                          </Link>
                                    </Card.Header>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col md={6}>
                                                            <Card>
                                                                  <Card.Body>
                                                                        <ListGroup>
                                                                              {product.banner_image && (
                                                                                    <div className="w-100">
                                                                                          <img
                                                                                                src={`${product.banner_image}`}
                                                                                                className="w-100 h-100"
                                                                                                style={{
                                                                                                      objectFit:
                                                                                                            'contain'
                                                                                                }}
                                                                                          />
                                                                                    </div>
                                                                              )}
                                                                              <ItemShow
                                                                                    title="Title"
                                                                                    value={
                                                                                          product.title
                                                                                    }
                                                                              />
                                                                              <ItemShow
                                                                                    title="Category"
                                                                                    value={
                                                                                          product.category_name
                                                                                    }
                                                                              />
                                                                              <ItemShow
                                                                                    title="Package Type"
                                                                                    value={`${product.package_type}`}
                                                                              />
                                                                              <ItemShow
                                                                                    title="Destination"
                                                                                    value={`${product.location}, ${product.destination}`}
                                                                              />
                                                                              <ItemShow
                                                                                    title="Destination Category"
                                                                                    value={
                                                                                          product.destination_category
                                                                                    }
                                                                              />
                                                                              <ItemShow
                                                                                    title="Duration"
                                                                                    value={
                                                                                          product.duration
                                                                                    }
                                                                              />

                                                                              <ItemShow
                                                                                    title="Group Size"
                                                                                    value={
                                                                                          product.group_size
                                                                                    }
                                                                              />

                                                                              <ItemShow
                                                                                    title="Price"
                                                                                    value={`${
                                                                                          product.offer_price ? (
                                                                                                <>
                                                                                                      {
                                                                                                            product.offer_price
                                                                                                      }
                                                                                                      <del className="ps-2">
                                                                                                            {
                                                                                                                  product.price
                                                                                                            }
                                                                                                      </del>
                                                                                                </>
                                                                                          ) : (
                                                                                                product.price
                                                                                          )
                                                                                    } ${
                                                                                          product.price_type
                                                                                    }`}
                                                                              />
                                                                              <ItemShow
                                                                                    title="Vehicle"
                                                                                    value={`${product.vehicle}`}
                                                                              />
                                                                              <ItemShow
                                                                                    title="Hotel Category"
                                                                                    value={`${product.hotel_category}`}
                                                                              />
                                                                              <ItemShow
                                                                                    title="User Name"
                                                                                    value={
                                                                                          product.user_name
                                                                                    }
                                                                              />
                                                                              <ItemShow
                                                                                    title="Seo Keyword"
                                                                                    value={
                                                                                          product.seo_keyword
                                                                                    }
                                                                              />
                                                                              <ItemShow
                                                                                    title="Seo Description"
                                                                                    value={
                                                                                          product.seo_description
                                                                                    }
                                                                              />

                                                                              <ItemShow
                                                                                    title="Status"
                                                                                    value={
                                                                                          product.status ? (
                                                                                                <>
                                                                                                      <span className="text-success">
                                                                                                            Active
                                                                                                      </span>
                                                                                                </>
                                                                                          ) : (
                                                                                                <>
                                                                                                      <span className="text-warning">
                                                                                                            Disable
                                                                                                      </span>
                                                                                                </>
                                                                                          )
                                                                                    }
                                                                              />
                                                                        </ListGroup>
                                                                  </Card.Body>
                                                            </Card>
                                                      </Col>
                                                      <Col md={6}>
                                                            <div>
                                                                  <ImageSlider
                                                                        image={
                                                                              product.image
                                                                        }
                                                                        images={
                                                                              product.images
                                                                        }
                                                                  />
                                                            </div>
                                                            {product.itineraries
                                                                  ?.length && (
                                                                  <div className="mt-4">
                                                                        <Accordion defaultActiveKey="0">
                                                                              {product
                                                                                    .itineraries
                                                                                    ?.length >
                                                                                    0 &&
                                                                                    product.itineraries?.map(
                                                                                          (
                                                                                                item,
                                                                                                index
                                                                                          ) => (
                                                                                                <Accordion.Item
                                                                                                      eventKey={`${index}`}
                                                                                                >
                                                                                                      <Accordion.Header>
                                                                                                            {
                                                                                                                  item.title
                                                                                                            }
                                                                                                      </Accordion.Header>
                                                                                                      <Accordion.Body>
                                                                                                            <div
                                                                                                                  dangerouslySetInnerHTML={{
                                                                                                                        __html: item.description
                                                                                                                  }}
                                                                                                            ></div>
                                                                                                      </Accordion.Body>
                                                                                                </Accordion.Item>
                                                                                          )
                                                                                    )}
                                                                        </Accordion>
                                                                  </div>
                                                            )}
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="text-center my-5">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default ProductShow;
