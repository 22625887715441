import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Dashboard from '../backend/dashboard';

import {
      BannerList,
      BannerCreate,
      BannerEdit,
      BannerShow
} from '../backend/banner';

import { PageList, PageCreate, PageEdit, PageShow } from '../backend/pages';
import { RoleCreate, RoleEdit, RoleList } from '../backend/roles';

import { UserCreate, UserEdit, UserList, UserShow } from '../backend/users';
import PageNotFound from '../components/PageNotFound';
import {
      DestinationCreate,
      DestinationEdit,
      DestinationList,
      DestinationShow
} from '../backend/destinations';
import {
      DestinationCategoryCreate,
      DestinationCategoryEdit,
      DestinationCategoryList,
      DestinationCategoryShow
} from '../backend/destination_categories';
import {
      HotelCategoryCreate,
      HotelCategoryEdit,
      HotelCategoryList,
      HotelCategoryShow
} from '../backend/hotel_categories';
import {
      VehicleCreate,
      VehicleEdit,
      VehicleList,
      VehicleShow
} from '../backend/vehicles';
import {
      PackageCategoryCreate,
      PackageCategoryEdit,
      PackageCategoryList,
      PackageCategoryShow
} from '../backend/package_categories';
import {
      PackageEnquiryList,
      PackageEnquiryShow
} from '../backend/package_enquiries';
import {
      PackageCreate,
      PackageEdit,
      PackageList,
      PackageShow
} from '../backend/packages';
import { AgentList, AgentShow } from '../backend/agents';
import { AgentRequestList, AgentRequestShow } from '../backend/agent_requests';
import {
      AirlineCreate,
      AirlineEdit,
      AirlineList,
      AirlineShow
} from '../backend/airlines';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Airline */}
                        <Route
                              path="/dashboard/airlines"
                              element={<AirlineList />}
                        />
                        <Route
                              path="/dashboard/airlines/create"
                              element={<AirlineCreate />}
                        />
                        <Route
                              path="/dashboard/airlines/:airlineId/edit"
                              element={<AirlineEdit />}
                        />
                        <Route
                              path="/dashboard/airlines/:airlineId/show"
                              element={<AirlineShow />}
                        />

                        {/* Agent */}
                        <Route
                              path="/dashboard/agents"
                              element={<AgentList />}
                        />
                        <Route
                              path="/dashboard/agents/:agentId/show"
                              element={<AgentShow />}
                        />

                        {/* Agent Request */}
                        <Route
                              path="/dashboard/agent_requests"
                              element={<AgentRequestList />}
                        />
                        <Route
                              path="/dashboard/agent_requests/:agentId/show"
                              element={<AgentRequestShow />}
                        />

                        {/* Destination */}
                        <Route
                              path="/dashboard/destinations"
                              element={<DestinationList />}
                        />
                        <Route
                              path="/dashboard/destinations/create"
                              element={<DestinationCreate />}
                        />
                        <Route
                              path="/dashboard/destinations/:destinationId/edit"
                              element={<DestinationEdit />}
                        />
                        <Route
                              path="/dashboard/destinations/:destinationId/show"
                              element={<DestinationShow />}
                        />

                        {/* Destination Category */}
                        <Route
                              path="/dashboard/destination_categories"
                              element={<DestinationCategoryList />}
                        />
                        <Route
                              path="/dashboard/destination_categories/create"
                              element={<DestinationCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/destination_categories/:categoryId/edit"
                              element={<DestinationCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/destination_categories/:categoryId/show"
                              element={<DestinationCategoryShow />}
                        />

                        {/* Hotel Category */}
                        <Route
                              path="/dashboard/hotel_categories"
                              element={<HotelCategoryList />}
                        />
                        <Route
                              path="/dashboard/hotel_categories/create"
                              element={<HotelCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/hotel_categories/:typeId/edit"
                              element={<HotelCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/hotel_categories/:typeId/show"
                              element={<HotelCategoryShow />}
                        />

                        {/* Vehicles */}
                        <Route
                              path="/dashboard/vehicles"
                              element={<VehicleList />}
                        />
                        <Route
                              path="/dashboard/vehicles/create"
                              element={<VehicleCreate />}
                        />
                        <Route
                              path="/dashboard/vehicles/:vehicleId/edit"
                              element={<VehicleEdit />}
                        />
                        <Route
                              path="/dashboard/vehicles/:vehicleId/show"
                              element={<VehicleShow />}
                        />

                        {/* Package */}
                        <Route
                              path="/dashboard/packages"
                              element={<PackageList />}
                        />
                        <Route
                              path="/dashboard/packages/create"
                              element={<PackageCreate />}
                        />
                        <Route
                              path="/dashboard/packages/:packageId/edit"
                              element={<PackageEdit />}
                        />
                        <Route
                              path="/dashboard/packages/:packageId/show"
                              element={<PackageShow />}
                        />

                        {/* Package Category */}
                        <Route
                              path="/dashboard/package_categories"
                              element={<PackageCategoryList />}
                        />
                        <Route
                              path="/dashboard/package_categories/create"
                              element={<PackageCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/package_categories/:categoryId/edit"
                              element={<PackageCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/package_categories/:categoryId/show"
                              element={<PackageCategoryShow />}
                        />

                        {/* Package Enquiry */}
                        <Route
                              path="/dashboard/package_enquiries"
                              element={<PackageEnquiryList />}
                        />
                        <Route
                              path="/dashboard/package_enquiries/:enquiryId/show"
                              element={<PackageEnquiryShow />}
                        />

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {/* Roles */}
                        <Route path="/dashboard/roles" element={<RoleList />} />
                        <Route
                              path="/dashboard/roles/create"
                              element={<RoleCreate />}
                        />
                        <Route
                              path="/dashboard/roles/:roleId/edit"
                              element={<RoleEdit />}
                        />

                        {/* User */}
                        <Route path="/dashboard/users" element={<UserList />} />
                        <Route
                              path="/dashboard/users/create"
                              element={<UserCreate />}
                        />
                        <Route
                              path="/dashboard/users/:userId/edit"
                              element={<UserEdit />}
                        />
                        <Route
                              path="/dashboard/users/:userId/show"
                              element={<UserShow />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
